import React from 'react';
import { connect } from 'react-redux';
import * as selectors from './Nav.selectors';
import SecondLevelLink from './Nav.second.levelLink';

const SecondLevelNav = ({ items, dispatch }) => (
  <div className="nav__second-level nav__second-level--desktop">
    {Object.keys(items).map((item) => (
      <SecondLevelLink key={items[item].url} item={items[item]} />
    ))}
  </div>
);

const mapStateToProps = (state) => ({
  secondLevel: selectors.secondLevel(state),
  items: selectors.secondLevelItems(state),
});

export default connect(mapStateToProps)(SecondLevelNav);

import React from "react";

export default () => (
  <svg
    width="10px"
    height="6px"
    viewBox="0 0 10 6"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Icons"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
      opacity="0.8"
    >
      <g
        id="Icon/Caret---Down"
        transform="translate(-7.000000, -9.000000)"
        fill="#838383"
      >
        <polygon id="Fill" points="7 9 12 15 17 9"></polygon>
      </g>
    </g>
  </svg>
);

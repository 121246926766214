import React from 'react';
import { connect } from 'react-redux';
import * as selectors from './Nav.selectors';
import { clickSecondLevel } from './Nav.actions';

const SecondLevelLink = ({ dispatch, hasThirdLevel, item }) => (
  <a
    href={item.url}
    className="nav__item nav__second-level-item nav__second-level-item--desktop"
    onClick={(e) => {
      // if nav has third level, open it
      if (hasThirdLevel) {
        e.preventDefault();
        e.stopPropagation();
        dispatch(clickSecondLevel(item.title));
      } else {
        // navigate to url
        window.location.href = item.url;
      }
    }}
  >
    <span className="nav__second-level-name">{item.title}</span>
  </a>
);

const mapStateToProps = (state, props) => ({
  hasThirdLevel: selectors.hasThirdLevel(state)(props.item.title),
});

export default connect(mapStateToProps)(SecondLevelLink);

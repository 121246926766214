export const firstLevel = (state) => state.firstLevel;
export const firstLevelItems = (state) => state.menu && state.menu.items;
export const navLoaded = (state) => state.navLoaded;
export const navOpen = (state) => state.navOpen;
export const menuName = (state) => state.menu && state.menu.name;
export const secondLevel = (state) => state.secondLevel;
export const secondLevelVisible = (state) => state.secondLevelVisible;
export const hasSecondLevel = (state) => (title) => {
  const firstLevelKey = Object.keys(state.menu.items).find(
    (key) => state.menu.items[key].title === title
  );
  const { child_items } = state.menu.items[firstLevelKey];
  return !!child_items;
};
export const url = (state) => (title) => {
  const firstLevelKey = Object.keys(state.menu.items).find(
    (key) => state.menu.items[key].title === title
  );
  const { url } = state.menu.items[firstLevelKey];
  return url;
};
export const secondLevelItems = (state) => {
  const firstLevelKey = Object.keys(state.menu.items).find(
    (key) => state.menu.items[key].title === secondLevel(state)
  );
  const { child_items } = state.menu.items[firstLevelKey];
  if (!child_items) return [];
  return child_items;
};

export const thirdLevel = (state) => state.thirdLevel;
export const thirdLevelVisible = (state) => state.thirdLevelVisible;
export const thirdLevelItems = (state) => {
  if (!thirdLevel(state)) return [];
  const secondLevelKey = Object.keys(secondLevelItems(state)).find(
    (key) => secondLevelItems(state)[key].title === thirdLevel(state)
  );
  const { child_items } = secondLevelItems(state)[secondLevelKey];
  if (!child_items) return [];
  return child_items;
};
export const hasThirdLevel = (state) => (title) => {
  const secondLevelKey = Object.keys(secondLevelItems(state)).find(
    (key) => secondLevelItems(state)[key].title === title
  );
  const { child_items } = secondLevelItems(state)[secondLevelKey];
  return !!child_items;
};

// Fourth level groupings are stored in nav item classes.
export const hasFourthLevel = (state) =>
  Object.keys(thirdLevelItems(state)).some(
    (item) =>
      thirdLevelItems(state)[item].classes &&
      thirdLevelItems(state)[item].classes.some((c) => !!c)
  );
export const fourthLevelItems = (state) => {
  const items = {};
  const tItems = Object.keys(thirdLevelItems(state)).map(
    (i) => thirdLevelItems(state)[i]
  );
  tItems.forEach((item) => {
    if (item.classes && item.classes.some((c) => !!c)) {
      item.classes.forEach((c) => {
        if (!(c in items)) {
          items[c] = [];
        }
        items[c].push(item);
      });
    }
  });
  return items;
};

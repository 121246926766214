import React from "react";
import { connect } from "react-redux";
import MediaQuery from "react-responsive";
import { fetchNav } from "../Nav/Nav.actions";
import FirstLevelNav from "../Nav/Nav.first.level";
import FirstLevelNavMobile from "../Nav/Nav.first.level.mobile";
import * as selectors from "../Nav/Nav.selectors";

class Header extends React.Component {
  componentDidMount() {
    const { dispatch, menuSlug } = this.props;
    dispatch(fetchNav(menuSlug));
  }
  render() {
    const { navLoaded, homeLink } = this.props;
    return (
      <>
        {/* Desktop header */}
        <MediaQuery minWidth={1280}>
          <header className="main-header">
            {navLoaded && <FirstLevelNav />}
          </header>
        </MediaQuery>
        {/* Mobile header */}
        <MediaQuery maxWidth={1280}>
          <>
            <header className="main-header main-header--mobile">
              {navLoaded && <FirstLevelNavMobile />}
            </header>
          </>
        </MediaQuery>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  navLoaded: selectors.navLoaded(state),
});

export default connect(mapStateToProps)(Header);

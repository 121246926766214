import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

const elem = document.querySelector(".wp-block-kehittamo-block-dynamic-nav");

if (elem) {
  const getMenuSlug = () => {
    if (elem) return elem.dataset.nav;
    return false;
  };

  const homeLink = () =>
    document.querySelector(".brand").querySelector("a").href;

  ReactDOM.render(<App menuSlug={getMenuSlug()} homeLink={homeLink()} />, elem);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React from "react";
import { connect } from "react-redux";
import * as selectors from "./Nav.selectors";
import { clickSecondLevel } from "./Nav.actions";

const SecondLevelNavMobile = ({ items, dispatch }) => (
  <div className="nav__second-level nav__second-level--mobile">
    {Object.keys(items).map(item => (
      <a
        href={items[item].url}
        className="nav__item nav__second-level-item nav__item nav__second-level-item--mobile"
        onClick={e => {
          e.stopPropagation();
          dispatch(clickSecondLevel(items[item].title));
        }}
      >
        <span className="nav__second-level-name">{items[item].title}</span>
      </a>
    ))}
  </div>
);

const mapStateToProps = state => ({
  secondLevel: selectors.secondLevel(state),
  items: selectors.secondLevelItems(state)
});

export default connect(mapStateToProps)(SecondLevelNavMobile);

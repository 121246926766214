import axios from 'axios';
import { put, takeEvery, select } from 'redux-saga/effects';
import * as selectors from './Nav.selectors';
import {
  CLICK_NAV,
  FETCH_NAV,
  CLICK_FIRST_LEVEL,
  CLICK_SECOND_LEVEL,
  setNav,
  setNavOpen,
  setNavLoaded,
  setSecondLevel,
  setSecondLevelVisible,
  setThirdLevel,
  setThirdLevelVisible,
} from './Nav.actions';

function* handleNavClick() {
  const navOpen = yield select(selectors.navOpen);
  if (navOpen) {
    yield put(setNavOpen(false));
  } else {
    yield put(setNavOpen(true));
  }
}

function* fetchNavSaga({ payload }) {
  const response = yield axios.get(`/wp-json/menus/v1/menus/${payload}`);
  if (response.status !== 200) {
    return;
  }
  const { data } = response;
  yield put(setNav(data));
  yield put(setNavLoaded(true));
}

function* goto(title) {
  const url = yield select((state) => selectors.url(state)(title));
  window.location.href = url;
}

function* handleFirstLevelClick({ payload }) {
  const hasSecondLevel = yield select((state) =>
    selectors.hasSecondLevel(state)(payload)
  );
  if (!hasSecondLevel) {
    goto(payload);
    return;
  }
  const secondLevelVisible = yield select(selectors.secondLevelVisible);
  const secondLevel = yield select(selectors.secondLevel);
  yield put(setThirdLevelVisible(false));
  yield put(setThirdLevel(''));
  yield put(setSecondLevel(payload));
  if (!secondLevelVisible) {
    yield put(setSecondLevelVisible(true));
  } else if (payload === secondLevel) {
    yield put(setSecondLevelVisible(false));
    yield put(setSecondLevel(''));
  }
}

function* handleSecondLevelClick({ payload }) {
  const thirdLevelVisible = yield select(selectors.thirdLevelVisible);
  const thirdLevel = yield select(selectors.thirdLevel);
  yield put(setThirdLevel(payload));
  if (!thirdLevelVisible) {
    yield put(setThirdLevelVisible(true));
  } else if (payload === thirdLevel) {
    yield put(setThirdLevelVisible(false));
    yield put(setThirdLevel(''));
  }
}

export default function* saga() {
  yield takeEvery(CLICK_NAV, handleNavClick);
  yield takeEvery(FETCH_NAV, fetchNavSaga);
  yield takeEvery(CLICK_FIRST_LEVEL, handleFirstLevelClick);
  yield takeEvery(CLICK_SECOND_LEVEL, handleSecondLevelClick);
}

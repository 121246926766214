import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import * as selectors from "./Nav.selectors";
import { clickFirstLevel, setNavOpen, clickNav } from "./Nav.actions";
import Caret from "./Nav.caret";
import SecondLevelNavMobile from "./Nav.second.level.mobile";

const FirstLevelNavMobile = ({
  items,
  dispatch,
  hasSecondLevel,
  menuName,
  navOpen,
  secondLevel,
  secondLevelVisible
}) => (
  <div
    className={classNames("nav__first-level nav__first-level--mobile", {
      open: navOpen
    })}
  >
    <div className="nav__item-container nav__item-container--mobile">
      <button
        className={classNames("hamburger", "hamburger--squeeze", {
          "is-active": navOpen
        })}
        onClick={() => {
          dispatch(clickNav());
          if (navOpen) {
            document.querySelector("body").classList.remove("nav-open");
          } else {
            document.querySelector("body").classList.add("nav-open");
          }
        }}
        type="button"
      >
        <span className="hamburger-box">
          <span className="hamburger-inner"></span>
        </span>
      </button>
      <div
        className="nav__item nav__first-level-item nav__first-level-item--mobile nav__first-level-item--name"
        onClick={() => dispatch(setNavOpen(!navOpen))}
      >
        
        { Object.keys(items).map(item => { 
            if ( items[item].classes[0] === 'dynamic-highlight' ) {
              return items[item].title
            }
        }
      )}
        
    
      </div>
    </div>
    {navOpen &&
      Object.keys(items).map(item => {
        const has2ndLevel = hasSecondLevel(items[item].title);
        return (
          <a
            className="nav__container"
            href={items[item].url}
            onClick={e => has2ndLevel && e.preventDefault()}
          >
            <div
              className={classNames({
                nav__item: true,
                "nav__first-level-item": true,
                "nav__first-level-item--mobile": true,
                "nav__first-level-item--active":
                  secondLevel === items[item].title
              })}
              onClick={() => dispatch(clickFirstLevel(items[item].title))}
            >
              <div className="nav__first-level-item-content">
                <span className="nav__first-level-name">
                  {items[item].title}
                </span>
                {has2ndLevel && (
                  <div className="nav__caret">
                    <Caret />
                  </div>
                )}
              </div>
              {secondLevelVisible && secondLevel === items[item].title && (
                <SecondLevelNavMobile />
              )}
            </div>
          </a>
        );
      })}
  </div>
);

const mapStateToProps = state => ({
  hasSecondLevel: selectors.hasSecondLevel(state),
  items: selectors.firstLevelItems(state),
  menuName: selectors.menuName(state),
  navOpen: selectors.navOpen(state),
  secondLevel: selectors.secondLevel(state),
  secondLevelVisible: selectors.secondLevelVisible(state)
});

export default connect(mapStateToProps)(FirstLevelNavMobile);

import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import * as selectors from './Nav.selectors';
import { clickFirstLevel } from './Nav.actions';
import Caret from './Nav.caret';
import SecondLevelNav from './Nav.second.level';

const FirstLevelNav = ({
  items,
  dispatch,
  hasSecondLevel,
  secondLevel,
  secondLevelVisible,
}) => (
  <div className="nav__first-level nav__first-level--desktop">
    {Object.keys(items).map((item) => {
      const has2ndLevel = hasSecondLevel(items[item].title);
      return (
        <a
          href={has2ndLevel ? '#' : items[item].url}
          onClick={(e) => {
            if (!!has2ndLevel) {
              e.preventDefault();
              e.stopPropagation();
            }
          }}
        >
          <div
            className={classNames({
              'nav__first-level-item--highlight':
                items[item].classes[0] === 'dynamic-highlight',
              nav__item: true,
              'nav__first-level-item': true,
              'nav__first-level-item--desktop': true,
              'nav__first-level-item--active':
                secondLevel === items[item].title,
            })}
            onClick={() => dispatch(clickFirstLevel(items[item].title))}
          >
            <span className="nav__first-level-name">{items[item].title}</span>
            {has2ndLevel && (
              <div className="nav__caret">
                <Caret />
              </div>
            )}

            {secondLevelVisible && secondLevel === items[item].title && (
              <SecondLevelNav />
            )}
          </div>
        </a>
      );
    })}
  </div>
);

const mapStateToProps = (state) => ({
  menuName: selectors.menuName(state),
  items: selectors.firstLevelItems(state),
  hasSecondLevel: selectors.hasSecondLevel(state),
  secondLevel: selectors.secondLevel(state),
  secondLevelVisible: selectors.secondLevelVisible(state),
});

export default connect(mapStateToProps)(FirstLevelNav);

import React from "react";
import { Provider } from "react-redux";

import store from "./store";
import Header from "./components/Header/Header";

const App = ({ menuSlug, homeLink }) => (
  <Provider store={store}>
    <Header menuSlug={menuSlug} homeLink={homeLink} />
  </Provider>
);

export default App;
